import { f7 } from 'framework7-react';

export const toast = (toastText, success) => {
  const closeBtnText = success ? 'Ok' : 'Close';
  const closeBtnColor = success ? 'green' : 'red';
  return f7.toast
    .create({
      text: toastText,
      closeButton: true,
      closeButtonText: closeBtnText,
      closeButtonColor: closeBtnColor,
    })
    .open();
};
