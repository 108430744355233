import { PAYMENT_BASE_URL } from '../config';
import BaseService from './BaseService';

export default class PaymentService {
  static getSavedCards = (customerId) => {
    const url = `${PAYMENT_BASE_URL}/customer/${customerId}/cards`;
    return BaseService.get(url);
  };

  static makePaymentUsingSavedCard = (data, intentId) => {
    const savedIntentUrl = `${PAYMENT_BASE_URL}/payment-intent/${intentId}/confirm`;
    return BaseService.post(savedIntentUrl, data);
  };

  static detachSavedCard = (pmId) => {
    const deatchUrl = `${PAYMENT_BASE_URL}/payment-method/${pmId}/detach`;
    return BaseService.post(deatchUrl, {});
  };
}
