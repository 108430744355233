import axios from 'axios';

export default class BaseService {
  static get = (url, { headers, params } = { headers: {} }) => {
    const newHeaders = { ...BaseService.getAuthHeader(), ...headers };
    const res = axios.get(url, { headers: newHeaders, params });
    res.catch((err) => {
      BaseService.handleAuthError(err);
    });

    return res;
  };

  static post = (url, body, { headers, params } = { headers: {} }) => {
    const newHeaders = { ...BaseService.getAuthHeader(), ...headers };
    const res = axios.post(url, body, { headers: newHeaders, params });
    res.catch((err) => BaseService.handleAuthError(err));

    return res;
  };

  static put = (url, body, { headers, params } = { headers: {} }) => {
    const newHeaders = { ...BaseService.getAuthHeader(), ...headers };
    const res = axios.put(url, body, { headers: newHeaders, params });
    res.catch((err) => BaseService.handleAuthError(err));
    return res;
  };

  static patch = (url, body, { headers, params } = { headers: {} }) => {
    const newHeaders = { ...BaseService.getAuthHeader(), ...headers };
    const res = axios.patch(url, body, { headers: newHeaders, params });
    res.catch((err) => BaseService.handleAuthError(err));
    return res;
  };

  static delete = (url, { headers, params } = { headers: {} }) => {
    const newHeaders = { ...BaseService.getAuthHeader(), ...headers };
    const res = axios.delete(url, { headers: newHeaders, params });
    res.catch((err) => BaseService.handleAuthError(err));
    return res;
  };

  static getAuthHeader = () => {
    const localStorage = global.window.localStorage;
    const token = localStorage.getItem('token')
      ? localStorage.getItem('token')
      : null;
    return {
      Authorization: `Bearer ${token}`,
    };
  };

  static handleAuthError = (err) => {
    if (!err.response) {
      return <div>Server error</div>;
    }
    switch (err.response && err.response.status) {
      case 401:
        break;
      case 403:
        break;
      case 400:
        break;
      case 500:
        break;
      case 404:
        break;
      default:
    }
  };
}
