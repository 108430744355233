/**
 * Use the CSS tab above to style your Element's container.
 */
import React from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import './Styles.css';
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};
const CardSection = () => {
  return (
    <>
      <label>
        Card number
        <CardNumberElement
          options={{
            ...CARD_ELEMENT_OPTIONS,
            showIcon: true,
            iconStyle: 'solid',
          }}
        />
      </label>
      <label>
        Expiration date
        <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
      </label>
      <label>
        CVC
        <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
      </label>
    </>
  );
};
export default CardSection;
