import Home from './pages/Home.js';

const routes = [
  {
    path: '/',
    component: Home,
  },
];

export default routes;
