import React from 'react';
import { App, View } from 'framework7-react';
import routes from './routes';
import store from './store';

const f7params = {
  name: 'Pouch',
  id: 'com.klikit.pouch',
  theme: 'md',
  routes,
};

const PouchApp = () => {
  return (
    <App
      { ...f7params }
      store={store}
    >
      <View main url="/" />
    </App>
  );
};

export default PouchApp;
