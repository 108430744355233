export default function getQueryParams() {
  const params = {};
  const searchString = window.location.search.substring(1);

  if (!searchString) {
    return params;
  }

  const segments = searchString.split('&');

  for (let ii = 0; ii < segments.length; ii++) {
    const parts = segments[ii].split('=');
    params[parts[0]] = decodeURIComponent(parts[1]);
  }

  return params;
};
