import React, { useContext, useEffect, useState } from 'react';
import {
  Page,
  Navbar,
  NavLeft,
  Link,
  NavTitle,
  NavRight,
  Icon,
  f7,
} from 'framework7-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import getQueryParams from '../utils/query-params';
import { PaymentContext } from '../context/PaymentContextProvider';
import CardSetupForm from './stripe/CardSetupForm';
import { STRIPE_PUBLIC_KEY } from '../config';
import SavedCardPaymentModal from './SavedCardPaymentModal';

const Home = () => {
  const [amountPayable, setAmountPayable] = useState(0);
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [savedCard, setSavedCard] = useState(null);
  const [currency, setCurrency] = useState(null);
  const paymentContext = useContext(PaymentContext);
  const {
    savedCards,
    getCustomerSavedCards,
    makePaymentUsingSavedCard,
    handleChangeClientSecret,
    deatchedSavedCard,
    sheetOpened,
    setSheetOpened,
    intentId,
    handleChangeIntentId,
  } = paymentContext || {};

  useEffect(() => {
    f7.dialog.preloader();
  }, []);
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

  stripePromise
    .then(() => {
      setLoading(false);
      f7.dialog.close();
    })
    .catch(() => setLoading(false));

  const mounted = () => {
    const params = getQueryParams();
    const urlToken = params.signedToken;
    const payableAmount = parseInt(params.amount) || 0;
    const amount = payableAmount / 100;

    global.window.localStorage.setItem('token', urlToken);
    global.window.localStorage.setItem('payable', payableAmount);

    setToken(urlToken);
    setAmountPayable(amount);
    setUserId(params.user_id);
    setCurrency(params.currency || null);
    handleChangeClientSecret(params.client_secret);
    handleChangeIntentId(params.intent_id);

    if (urlToken && params.user_id) {
      getCustomerSavedCards(params.user_id);
    }
  };

  const handleSavedCardClick = (card) => {
    setSheetOpened(true);
    setSavedCard(card);
  };

  const handlePaymentUsingSavedCard = () => {
    const { id } = savedCard || {};
    const data = {
      payment_method_id: id,
    };
    f7.dialog.preloader();
    if (intentId) makePaymentUsingSavedCard(data);
  };

  const handleDetachCard = () => {
    f7.dialog.preloader();
    deatchedSavedCard(savedCard, userId);
  };

  const payableAmount =
    amountPayable > 0 ? (
      <div className="flex font-bold mb-4 items-center flex-col">
        <div className="text-grey">You need to pay</div>
        <div className="text-xl">
          {currency}
          {amountPayable.toFixed(2)}
        </div>
      </div>
    ) : null;

  const cardList =
    savedCards.length > 0 ? (
      <div className="flex flex-col">
        <div className="text-grey mb-2 mt-4">Pay with your saved card</div>
        {savedCards.map((card, cardIndex) => (
          <div
            key={cardIndex}
            className="flex pt-4 pb-4 pl-4 pr-2 rounded mb-4 border"
            onClick={() => handleSavedCardClick(card)}
          >
            <div className="card-info">
              <div className="font-bold">**** **** **** {card.card.last4}</div>
              <div className="text-sm text-grey capitalize">
                {card.card.brand}
              </div>
            </div>
            <Icon icon="sc-arrow-right text-xl flex items-center text-grey" />
          </div>
        ))}
        <div className="font-bold mb-4 mt-4">
          Not the right card? Pay with a new one!
        </div>
      </div>
    ) : null;

  const renderStripeElement = loading ? null : (
    <Elements stripe={stripePromise}>
      <CardSetupForm />
    </Elements>
  );

  return (
    <Page onPageInit={mounted} className="PouchHome">
      <Navbar sliding={false}>
        <NavLeft backLink="">
          <Link
            external
            href="/?status=exit"
            icon="sc-arrow-right"
            className="flex items-center justify-center text-xl back-link"
            color="black"
          />
        </NavLeft>
        <NavTitle className="flex flex-1 items-center justify-center">
          Complete Purchase
        </NavTitle>
        <NavRight />
      </Navbar>
      <aside className="p-4">
        {payableAmount}
        {cardList}
        {renderStripeElement}
      </aside>
      <SavedCardPaymentModal
        sheetOpened={sheetOpened}
        amount={amountPayable}
        currency={currency}
        closeByBackdropClick={false}
        closeByOutsideClick={false}
        handleDetachCard={handleDetachCard}
        handlePayment={handlePaymentUsingSavedCard}
      />
    </Page>
  );
};

export default Home;
