import React, { useContext, useState } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';

import CardSection from './CardSection';
import { Button, Checkbox, Preloader } from 'framework7-react';
import { PaymentContext } from '../../context/PaymentContextProvider';
import { changeUrlStatus } from '../../utils/changeUrlStatus';

const CardSetupForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [saveCardChecked, setSaveCardChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const paymentContext = useContext(PaymentContext);
  const { clientSecret } = paymentContext || {};
  let defaultClass = 'font-bold normal-case bg-purple';

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    const data = {
      card: elements.getElement(CardNumberElement),
      billing_details: {},
    };

    const setUpFutureUsage = saveCardChecked ? 'off_session' : '';

    stripe
      .confirmCardPayment(clientSecret, {
        payment_method: data,
        setup_future_usage: setUpFutureUsage,
      })
      .then(function (result) {
        setLoading(false);
        if (result?.paymentIntent) {
          changeUrlStatus('success');
        } else if (result?.error?.type !== 'validation_error') {
          changeUrlStatus('fail');
        }
      });
  };

  const btnClass = `${defaultClass} ${!stripe || loading ? 'disabled' : ''}`;
  const btnAttr = loading ? (
    <Preloader color="white" size="25"></Preloader>
  ) : (
    'Pay now'
  );

  return (
    <form onSubmit={handleSubmit}>
      <CardSection />
      <Checkbox
        checked={saveCardChecked}
        onChange={(e) => setSaveCardChecked(e.target.checked)}
        className="w-full flex mb-4 no-ripple items-center"
      >
        <div className="pl-2">Save my card for future payment</div>
      </Checkbox>
      <Button type="submit" fill className={btnClass}>
        {btnAttr}
      </Button>
    </form>
  );
};

export default CardSetupForm;
