import { Link, Sheet, f7, Icon } from 'framework7-react';
import React from 'react';
import { useContext } from 'react';
import { PaymentContext } from '../context/PaymentContextProvider';

const SavedCardPaymentModal = ({
  sheetOpened,
  closeByOutsideClick,
  closeByBackdropClick,
  onSheetClosed,
  handleDetachCard,
  handlePayment,
  amount,
  currency,
}) => {
  const paymentContext = useContext(PaymentContext);
  const { setSheetOpened } = paymentContext || {};
  const handleDetachButtonClick = () => {
    f7.dialog
      .create({
        text: 'Are you sure to remove this card?',
        buttons: [
          {
            text: 'Keep',
            cssClass: 'text-purple font-bold',
            close: true,
          },
          {
            text: 'Remove',
            cssClass: 'text-red font-bold',
            onClick: handleDetachCard,
          },
        ],
        verticalButtons: false,
      })
      .open();
  };

  const closeSheetModal = () => {
    setSheetOpened(false);
  };
  return (
    <Sheet
      className="demo-sheet"
      opened={sheetOpened}
      closeByOutsideClick={closeByOutsideClick}
      closeByBackdropClick={closeByBackdropClick}
      onSheetClosed={onSheetClosed}
    >
      <div className="modal-icon">
        <Link sheetClose onClick={closeSheetModal}>
          <Icon f7="xmark"></Icon>
        </Link>
      </div>
      <div className="modal-content">
        <p className="modal-title">
          You'll be charged {currency}
          {amount}. Do you want to continue with payment?
        </p>
        <div className="modal-button">
          <button
            onClick={handleDetachButtonClick}
            style={{ marginRight: '10px', backgroundColor: 'red' }}
          >
            Remove card
          </button>
          <button onClick={handlePayment}>Pay now</button>
        </div>
      </div>
    </Sheet>
  );
};

export default SavedCardPaymentModal;
