import React from 'react';
import ReactDOM from 'react-dom';
import Framework7 from 'framework7/lite-bundle';
import Framework7React from 'framework7-react';
import App from './App.jsx';
import 'framework7-icons';

import 'framework7/framework7-bundle.css';
import './assets/styles/fonts.css';
import './assets/styles/index.css';
import './assets/styles/App.css';
import './assets/styles/modal.css';
import PaymentContextProvider from './context/PaymentContextProvider.js';

Framework7.use(Framework7React);

// Mount React App
ReactDOM.render(
  <PaymentContextProvider>
    <App />
  </PaymentContextProvider>,
  document.getElementById('root'),
);
