// Production config
export const API_BASE_URL = 'https://gateway-consumer.klikit.io/v1';
export const PAYMENT_BASE_URL = `${API_BASE_URL}/payment`;
export const STRIPE_PUBLIC_KEY =
  'pk_live_51IhV78FuWPXcJG3PtId3ZmbemDphIR8AB1NoJam4sdGhVFHcFqGYbo4GvzteThHMRRUXIfwHsbkxlafgxhiAKBVD00JEp69rL3';

// Staging config
// export const API_BASE_URL = 'https://consumer-qa.shadowchef.co/v1';
// export const PAYMENT_BASE_URL = `${API_BASE_URL}/payment`;
// export const STRIPE_PUBLIC_KEY =
//   'pk_test_51HIEwrKJPEDtqQopwNT1EZkc5hIEp9UNvjbFqIZTq4xLdvU29MbVztYx1rdElLYBqZCQ07ISDEBi7EkhcGusu70K00RQ9TndEI';
