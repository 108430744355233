import { f7 } from 'framework7-react';
import React, { createContext, useState } from 'react';
import PaymentService from '../services/PaymentService';
import { changeUrlStatus } from '../utils/changeUrlStatus';
import { toast } from '../utils/toast';

export const PaymentContext = createContext();

const PaymentContextProvider = ({ children }) => {
  const [savedCards, setSavedCards] = useState([]);
  const [clientSecret, setClientSecret] = useState(null);
  const [intentId, setIntentId] = useState(null);
  const [sheetOpened, setSheetOpened] = useState(false);

  const handleChangeClientSecret = (val) => {
    setClientSecret(val);
  };

  const handleChangeIntentId = (id) => {
    setIntentId(id);
  };

  const getCustomerSavedCards = (customerId) => {
    PaymentService.getSavedCards(customerId)
      .then((res) => {
        setSavedCards(res.data);
      })
      .catch((err) => {
        const { data, status } = err.response || {};
        const { message } = data || {};
        if (status === 404) {
          setSavedCards([]);
        } else {
          changeUrlStatus('fail');
          const toastMsg = message || data || 'Something went wrong';
          toast(toastMsg, false);
        }
      });
  };

  const deatchedSavedCard = (card, cusId) => {
    const { id } = card || {};
    PaymentService.detachSavedCard(id)
      .then((res) => {
        f7.dialog.close();
        setTimeout(() => setSheetOpened(false), 300);
        toast('Successfully removed card', true);
        getCustomerSavedCards(cusId);
      })
      .catch((err) => {
        f7.dialog.close();
        const { data, status } = err.response || {};
        const { message } = data || {};
        if (status === 401) {
          changeUrlStatus('fail');
        }
        const toastMsg = message || data || 'Something went wrong';
        toast(toastMsg, false);
      });
  };

  const makePaymentUsingSavedCard = (data) => {
    PaymentService.makePaymentUsingSavedCard(data, intentId)
      .then(() => {
        f7.dialog.close();
        setTimeout(() => setSheetOpened(false), 300);
        changeUrlStatus('success');
      })
      .catch((err) => {
        changeUrlStatus('fail');
        f7.dialog.close();
        setTimeout(() => setSheetOpened(false), 300);
        const { data } = err.response || {};
        const { message } = data || {};
        const toastMsg = message || data || 'Something went wrong';
        toast(toastMsg, false);
      });
  };

  return (
    <PaymentContext.Provider
      value={{
        savedCards,
        clientSecret,
        sheetOpened,
        setSheetOpened,
        intentId,
        handleChangeIntentId,
        getCustomerSavedCards,
        deatchedSavedCard,
        makePaymentUsingSavedCard,
        handleChangeClientSecret,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentContextProvider;
